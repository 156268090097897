<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="4">
            <label for="value-name">
                {{ $t('modals.createContext.value') }}
            </label>
            </b-col>
            <b-col cols="8">
            <div class="mt-3">
                <b-form-input
                    v-model="leftValue"
                    debounce="500"
                    @update="updateValues"
                    @keyup.enter="createUpdateEntry"
                >
                </b-form-input>
                <b-form-text>
                {{ $t('modals.createContext.valueDesc') }}
                </b-form-text>
            </div>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col cols="4">
            <label for="value-name">
                {{ $t('modals.createContext.operator') }}
            </label>
            </b-col>
            <b-col cols="8">
            <div class="d-inline-block">
                <b-form-select
                    v-model="operator"
                    :options="operatorOptions"
                    @change="updateValues"
                >
                </b-form-select>
            </div>
            <b-form-text>
                {{ $t('modals.createContext.operatorDesc') }}
            </b-form-text>
            </b-col>
        </b-row>
        <b-row v-if="!usePersonalizationSetAsContext">
            <b-col cols="4">
            <label for="value-name">
                {{ $t('modals.createContext.rightValue') }}
            </label>
            </b-col>
            <b-col cols="8">
            <b-form-input
                id="entry-name"
                v-model="rightValue"
                debounce="500"
                @keyup.enter="createUpdateEntry"
                @update="updateValues"
            >
            </b-form-input>
            <b-form-text>
                {{ $t('modals.createContext.rightValueDesc') }}
            </b-form-text>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'ContextItemUrlParam',
  props: {
    operatorOptions: {
      type: Array,
      required: true,
    },
    selectedLeftValue: {
      type: String,
    },
    selectedOperator: {
      type: String,
    },
    selectedRightValue: {
      type: String,
    },
  },
  created() {
    this.leftValue = this.selectedLeftValue;
    this.operator = this.selectedOperator;
    this.rightValue = this.selectedRightValue;
  },
  data() {
    return {
      usePersonalizationSetAsContext: false,
      leftValue: null,
      operator: null,
      rightValue: null,
    };
  },
  methods: {
    updateValues() {
      this.$emit('set-url-parameter', {
        leftValue: this.leftValue,
        operator: this.operator,
        rightValue: this.rightValue,
      });
    },
    createUpdateEntry() {
      if (this.leftValue && this.rightValue && this.operator) {
        this.$emit('trigger-create-update-context');
      }
    },
  },
};
</script>
